import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from './Modal.jsx';

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  color: #333;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
  }
`;

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #E7E6E4;
  border-bottom: 1px solid black;
  padding: 20px 20px 20px 100px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 15px;
    text-align: center;
  }
`;

const Title = styled.h1`
  text-transform: uppercase;
  font-weight: regular;
  font-size: 36px;
  line-height: 1.6;
  font-family: 'Helvetica Neue', sans-serif;
  color: #000000;
`;

const Button = styled.button`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 24px;
  line-height: 1.6;
  text-transform: uppercase;
  background: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: 20px;
`;

const Header = ({ onOpenModal, toggleMobileMenu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <HeaderWrapper>
      <Title>Meina Ghafouri</Title>
      <MobileMenuButton onClick={toggleMobileMenu}>☰</MobileMenuButton>
      <nav>
        {<Button onClick={() => setIsModalOpen(true)}>Contact</Button>}
      </nav>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </HeaderWrapper>
  );
};

Header.propTypes = {
  onOpenModal: PropTypes.func.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired
};

export default Header;
